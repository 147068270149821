<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-card :loading="loading">
      <v-tabs v-model="tab">
        <v-tab>{{ $store.getters.translate("edit") }}</v-tab>
        <v-tab @click="preview()">{{ $store.getters.translate("preview") }}</v-tab>
        <v-toolbar dense flat>
          <v-spacer/>
          <v-btn v-if="canDelete(record.id || 0)" text small @click="archive()" class="mr-2" color="secondary">
            <v-icon color="error">mdi-archive</v-icon>
          </v-btn>
          <v-btn v-if="canEdit(record.id || 0)" text small @click="save()" color="primary">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tab-item>
          <v-row>
            <v-col cols="3">
              <v-card flat>
                <v-card-text>
                  <template v-for="field in usual_fields">
                    <base-field
                        :isDisabled="!canEdit(record.id || 0)"
                        :key="field.name"
                        :value="record[field.name]"
                        :field="field"
                        @change="usualFieldDataChanged"/>
                  </template>
                  <template v-if="record.custom_fields">
                    <template v-for="field in custom_fields">
                      <base-field
                          :isDisabled="!canEdit(record.id || 0)"
                          :key="field.name"
                          :value="record.custom_fields[field.name]"
                          :field="field"
                          @change="customFieldDataChanged"/>
                    </template>
                  </template>
                  <v-row class="mb-5">
                    <v-col>
                      <v-text-field
                        dense
                        :label="$store.getters.translate('margin_top')"
                        v-model="record.margin_top"
                        suffix="mm"/>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        :label="$store.getters.translate('margin_bottom')"
                        v-model="record.margin_bottom"
                        suffix="mm"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        :label="$store.getters.translate('margin_left')"
                        v-model="record.margin_left"
                        suffix="mm"/>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        :label="$store.getters.translate('margin_right')"
                        v-model="record.margin_right"
                        suffix="mm"/>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5">
                    <v-col>
                      <v-btn text color="primary" @click="showHeader()" block>{{ $store.getters.translate("header") }}</v-btn>
                      <BaseDraggableModal
                          :name="header_modal_name"
                          max-height="80vh"
                          width="70vw"
                          :fullscreen="$vuetify.breakpoint.xsOnly"
                          :ref="header_modal_name">
                        <v-card>
                          <BaseModal>
                            <v-toolbar slot="header" class="draggable_selector" dark color="primary" dense flat>
                              <v-btn icon @click="closeModal(header_modal_name)">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                              <v-toolbar-title>{{ $store.getters.translate("header") }}</v-toolbar-title>
                              <v-spacer/>
                              <v-btn @click="save(); closeModal(header_modal_name)" text small>
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <div slot="content">
                              <v-divider/>
                              <v-card-text>
                                <base-editor ref="header_editor" :label="$store.getters.translate('header')" v-model="record.header" :key="key_header" height="500"/>
                              </v-card-text>
                            </div>
                          </BaseModal>
                        </v-card>
                      </BaseDraggableModal>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5">
                    <v-col>
                      <v-btn text color="primary" @click="showFooter()" block>{{ $store.getters.translate("footer") }}</v-btn>
                      <BaseDraggableModal
                          :name="footer_modal_name"
                          max-height="80vh"
                          width="70vw"
                          :fullscreen="$vuetify.breakpoint.xsOnly"
                          :ref="footer_modal_name">
                        <v-card>
                          <BaseModal>
                            <v-toolbar slot="header" class="draggable_selector" dark color="primary" dense flat>
                              <v-btn icon @click="closeModal(footer_modal_name)">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                              <v-toolbar-title>{{ $store.getters.translate("footer") }}</v-toolbar-title>
                              <v-spacer/>
                              <v-btn @click="save();closeModal(footer_modal_name)" text small>
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <div slot="content">
                              <v-divider/>
                              <v-card-text>
                                <base-editor ref="footer_editor" :label="$store.getters.translate('footer')" v-model="record.footer" :key="key_footer" height="500"/>
                              </v-card-text>
                            </div>
                            </BaseModal>
                        </v-card>
                      </BaseDraggableModal>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="mb-5">{{ $store.getters.translate("background_image") }}</span>
                      <v-file-input
                        v-if="!record.background_image"
                        accept="image/*"
                        :label="$store.getters.translate('background_image')"
                        show-size
                        @change="saveImage"
                        v-model="file"/>
                      <div class="text-center" v-else>
                        <v-img :src="record.background_image.full_path" max-height="150" contain style="border: 1px solid lightgrey"/>
                        <v-btn @click="deleteImage()" text color="red" class="mt-2">
                          <v-icon color="error">mdi-trash-can</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" v-model="record.tags" :modal_name="page.name"/>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-divider vertical/>
            <v-col>
              <v-card flat>
                <v-card-text>
                  <editor
                    class="mb-5"
                    @preview="preview()"
                    :label="$store.getters.translate('content')"
                    v-model="record.html"
                    :module_fields="template_fields"
                    style="height: 100%"
                    ref="rte_main"
                    :margins="margins"
                    :background_url="backgroundUrl"/>
                </v-card-text>
              </v-card>
            </v-col>
            <v-divider vertical/>
            <v-col cols="2">
              <v-card flat class="sticky">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <div>
                        <v-text-field
                          v-model="search"
                          :label="$store.getters.translate('search')"
                          flat
                          hide-details
                          clearable
                          clear-icon="mdi-close-circle-outline"
                          prepend-icon="mdi-magnify"/>
                        <v-treeview
                          :search="search"
                          transition
                          item-key="name"
                          dense
                          :items="template_fields"
                          item-text="name">
                          <template v-slot:label="{ item }">
                            <a @click="insertContent(item)">{{ $store.getters.translate(item.name) }}</a>
                          </template>
                        </v-treeview>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-card flat style="height: 100%">
            <v-card-text style="height: 100%">
              <v-layout
                v-if="!preview_url"
                align-center
                justify-center
                column
                fill-height>
                <v-flex row align-center>
                  <div class="justify-center">
                    <v-progress-circular :size="100" color="primary" indeterminate/>
                    <transition name="fade">
                      <h3 class="mt-5">{{ $store.getters.translate("loading") }}</h3>
                    </transition>
                  </div>
                </v-flex>
              </v-layout>
              <p v-else align="center" style="height: 100%" class="mt-5">
                <iframe
                  ref="preview"
                  style="overflow: hidden; height: 1000px; width: 100%"
                  height="100%"
                  width="800px"
                  :src="preview_url"
                  frameborder="0"/>
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "./editor/Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseBreadcrumb,
    Editor,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      header_modal_name: 'show_header',
      footer_modal_name: 'show_footer',
      page: {
        title: this.$store.getters.translate("templates"),
        name: "templates",
        model: "template"
      },
      record: {},
      template_fields: [],
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
      show_header: false,
      show_footer: false,
      key_header: 0,
      key_footer: 0,
      search: null,
      tab: 0,
      file: null,
      preview_url: null,
    };
  },
  created() {
    this.load();
    this.getTemplateFields();
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      if (this.record.html) {
        this.preview();
      }
      await this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    getTemplateFields() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/fields")
        .then((response) => {
          this.loading = false;
          this.template_fields = this.$lodash
            .chain(response.data)
            .groupBy("model_type")
            .map((value, key) => ({
              name: this.$store.getters.translate(key.toLowerCase()),
              children: value,
            }))
            .value();
          this.template_fields = this.$lodash.sortBy(this.template_fields, "name");
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    saveImage() {
      const formData = new FormData();
      formData.append("background_image", this.file, "name.jpeg");
      this.$http
        .post(this.$store.getters.appUrl + "v2/templates/file?id=" + this.record.id, formData, { headers: { "Content-Type": "multipart/form-data" }})
        .then(() => {
          this.load();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    deleteImage() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/templates/file/delete?id=" + this.record.id)
        .then(() => {
          this.load();
          this.file = null;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    preview() {
      this.save();
      this.loading = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/pdf/preview", this.record)
        .then((response) => {
          this.loading = false;
          this.preview_url = response.data;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    insertContent(item) {
      if (item.name) {
        let model_type = item.model_type.toLowerCase() + ".";
        let source = item.source ? item.source.toLowerCase() + "." : "";
        let name = item.name.toLowerCase();
        this.$refs.rte_main.$refs.text_editor.editor.insertContent("${" + model_type + source + name + "}");
      }
    },
    showHeader() {
      this.openModal(this.header_modal_name);
      this.key_header = this.key_header + 1;
    },
    showFooter() {
      this.openModal(this.footer_modal_name);
      this.key_footer = this.key_footer + 1;
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    canEdit(id = 0) {
      return this.$can('edit', this.$createEntity(this.page.model, {id}));
    },
    canDelete(id = 0) {
      return this.$can("delete", this.$createEntity(this.page.model, {id}));
    },
  },
  computed: {
    margins() {
      return {
        margin_top: this.record.margin_top,
        margin_bottom: this.record.margin_bottom,
        margin_left: this.record.margin_left,
        margin_right: this.record.margin_right,
      };
    },
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
    backgroundUrl() {
      return this.record.background_image ? this.record.background_image.full_path : "";
    },
  },
  watch: {
    show_header: {
      handler() {
        this.$refs.header_editor.value = this.record.header;
      },
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 30px;
}
</style>